import axios from '@axios'

export default {
  namespaced: true,
  state: {
    tableFields: [
      { key: 'title', sortable: true },
      { key: 'questions' },
    ],
    sortBy: null,
  },
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('wizards', { params: queryParams })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    fetch(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`wizards/${params.id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    post(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('wizards', params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    update(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`wizards/${params.id}`, params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    postResult(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('results', params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    updateResult(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`results/${params.id}`, params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
