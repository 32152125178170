<template>
  <div>
    <div
      ref="questionsForm"
      class="repeater-form"
    >
      <draggable
        v-model="data.questions"
        handle=".questions-handle"
        @end="sortQuestions()"
      >
        <b-row
          v-for="(question, index) in data.questions"
          :id="question.id"
          :key="question.id"
          ref="rowQuestion"
        >
          <b-col md="12">
            <div class="d-flex border rounded flex-column mb-1 position-relative">
              <b-link
                class="position-absolute position-right-0 zindex-1 repeater-delete"
                @click="removeQuestion(index)"
              >
                <feather-icon
                  icon="XIcon"
                  size="16"
                />
              </b-link>

              <div class="row flex-grow-1 px-2 py-1">
                <b-col class="flex-grow-0">
                  <feather-icon
                    icon="AlignJustifyIcon"
                    size="20"
                    class="questions-handle"
                  />
                </b-col>
                <b-col>
                  <div class="d-flex">
                    <b-form-group
                      label="Title"
                      label-for="question-title"
                      class="flex-grow-1"
                    >
                      <b-form-input
                        id="question-title"
                        v-model="question.title"
                        trim
                      />
                    </b-form-group>
                    <div class="d-flex align-items-center px-2">
                      <b-link v-b-toggle="`question-${question.id}`">
                        <feather-icon
                          icon="ChevronDownIcon"
                          size="20"
                        />
                      </b-link>
                    </div>
                  </div>

                  <b-collapse :id="`question-${question.id}`">
                    <draggable
                      v-model="question.answers"
                      handle=".answers-handle"
                      @end="sortAwnsers()"
                    >
                      <b-row
                        v-for="(answer, aIndex) in question.answers"
                        :id="answer.id"
                        :key="answer.id"
                        ref="rowAnswer"
                      >
                        <b-col md="12">
                          <div class="d-flex border rounded flex-column mb-1 position-relative">
                            <b-link
                              class="position-absolute position-right-0 zindex-1 repeater-delete"
                              @click="removeAnswer(index, aIndex)"
                            >
                              <feather-icon
                                icon="XIcon"
                                size="16"
                              />
                            </b-link>

                            <b-row class="flex-grow-1 px-2 py-1">
                              <b-col class="flex-grow-0">
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  size="20"
                                  class="answers-handle"
                                />
                              </b-col>
                              <b-col>
                                <div class="d-flex">
                                  <b-form-group
                                    label="Title"
                                    label-for="answer-title"
                                    class="flex-grow-1"
                                  >
                                    <b-form-input
                                      id="answer-title"
                                      v-model="answer.title"
                                      trim
                                    />
                                  </b-form-group>

                                  <div class="d-flex align-items-center px-2">
                                    <b-link v-b-toggle="`answer-${answer.id}`">
                                      <feather-icon
                                        icon="ChevronDownIcon"
                                        size="20"
                                      />
                                    </b-link>
                                  </div>
                                </div>

                                <b-collapse :id="`answer-${answer.id}`">
                                  <b-row
                                    v-for="(resultPoint, rIndex) in answer.resultPoints"
                                    :id="resultPoint.id"
                                    :key="resultPoint.id"
                                    ref="row"
                                  >
                                    <b-col md="12">
                                      <div class="d-flex border rounded flex-column mb-1 position-relative">
                                        <b-link
                                          class="position-absolute position-right-0 zindex-1 repeater-delete"
                                          @click="removeAnswerResult(index, aIndex, rIndex)"
                                        >
                                          <feather-icon
                                            icon="XIcon"
                                            size="16"
                                          />
                                        </b-link>

                                        <div class="row flex-grow-1 px-2 pt-1">
                                          <b-col>
                                            <b-form-group
                                              label="Result"
                                              label-for="result-result"
                                            >
                                              <v-select
                                                v-model="resultPoint.result"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="data.results"
                                                :clearable="false"
                                                label="title"
                                                class="bg-white"
                                                :reduce="result => result['@id']"
                                              />
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group
                                              label="Points"
                                              label-for="result-points"
                                            >
                                              <b-form-input
                                                id="result-points"
                                                v-model.number="resultPoint.points"
                                                trim
                                              />
                                            </b-form-group>
                                          </b-col>
                                        </div>

                                      </div>
                                    </b-col>
                                  </b-row>

                                  <b-link
                                    variant="primary"
                                    @click="addResultPoint(index, aIndex)"
                                  >
                                    <feather-icon
                                      icon="PlusIcon"
                                      class="mr-25"
                                    />
                                    <span>Add result points</span>
                                  </b-link>
                                </b-collapse>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </draggable>

                    <b-link
                      variant="primary"
                      @click="addAnswer(index)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      <span>Add answer</span>
                    </b-link>
                  </b-collapse>
                </b-col>
              </div>
            </div>
          </b-col>
        </b-row>
      </draggable>
    </div>

    <b-link
      variant="primary"
      @click="addQuestion"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add question</span>
    </b-link>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BLink, BCollapse, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    BCollapse,
    vSelect,
    draggable,
  },
  directives: { 'b-toggle': VBToggle },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addQuestion() {
      this.data.questions.push({
        title: null,
        answers: [],
        position: this.data.questions.length,
      })
    },
    addAnswer(index) {
      this.data.questions[index].answers.push({
        title: null,
        url: null,
        description: null,
        resultPoints: [],
        position: this.data.questions[index].answers.length,
      })
    },
    addResultPoint(index, aIndex) {
      this.data.questions[index].answers[aIndex].resultPoints.push({
        result: null,
        points: 0,
      })
    },
    removeQuestion(index) {
      this.data.questions.splice(index, 1)
    },
    removeAnswer(index, aIndex) {
      this.data.questions[index].answers.splice(aIndex, 1)
    },
    removeAnswerResult(index, aIndex, rIndex) {
      this.data.questions[index].answers[aIndex].resultPoints.splice(rIndex, 1)
    },
    sortQuestions() {
      this.data.questions.forEach((question, index) => {
        // eslint-disable-next-line radix
        this.data.questions[index].position = parseInt(index)
      })
    },
    sortAwnsers() {
      this.data.questions.forEach((question, index) => {
        question.answers.forEach((answer, aIndex) => {
          // eslint-disable-next-line radix
          this.data.questions[index].answers[aIndex].position = parseInt(aIndex)
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-delete {
  padding: .25rem .5rem;
}
</style>
