<template>
  <b-row>
    <b-col md="8">
      <b-card title="Wizard">
        <b-tabs>
          <b-tab title="Vragen">
            <card-questions
              :data="data"
            />
          </b-tab>
          <b-tab title="Resultaten">
            <card-results
              :results="data.results"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
    <b-col>
      <b-card title="Widget">
        <card-wizard
          :data="data"
        />

        <b-button
          variant="primary"
          @click="saveWizard()"
        >
          Save
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTabs, BTab,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import CardWizard from './CardWizard.vue'
import wizardStoreModule from './wizardStoreModule'
import CardQuestions from './CardQuestions.vue'
import CardResults from './CardResults.vue'

export default {
  components: {
    CardWizard,
    CardResults,
    CardQuestions,
    BRow,
    BCol,
    BCard,
    BButton,
    BTabs,
    BTab,
  },
  setup() {
    const data = ref({
      title: null,
      questions: [],
      results: [],
      startPage: 0,
    })

    const WIZARD_STORE_MODULE_NAME = 'wizards'

    // Register module
    if (!store.hasModule(WIZARD_STORE_MODULE_NAME)) store.registerModule(WIZARD_STORE_MODULE_NAME, wizardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WIZARD_STORE_MODULE_NAME)) store.unregisterModule(WIZARD_STORE_MODULE_NAME)
    })

    const { id } = router.currentRoute.params
    store.dispatch('wizards/fetch', { id }).then(response => {
      data.value = response
    })

    return {
      id,
      data,
    }
  },
  methods: {
    saveWizard() {
      // First save all results
      const requests = this.data.results.map((value, index) => {
        const result = value
        return new Promise(resolve => {
          if (result.id) {
            store.dispatch('wizards/updateResult', result).then(response => {
              resolve(response)
            })
          } else {
            result.wizard = `/api/wizards/${this.id}`
            store.dispatch('wizards/postResult', result).then(response => {
              this.data.results[index] = response
              resolve(response)
            })
          }
        })
      })
      Promise.all(requests).then(() => {
        const wizard = JSON.parse(JSON.stringify(this.data))
        const { results } = wizard
        wizard.results = []
        results.forEach(result => {
          wizard.results.push(result['@id'])
        })

        store.dispatch('wizards/update', wizard).then(response => {
          const updatedWizard = response
          updatedWizard.results = results
          this.data = updatedWizard
        })
      })
    },
  },
}
</script>

<style>

</style>
