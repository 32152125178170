<template>
  <div>
    <b-form-group
      label="Title"
      label-for="title"
    >
      <b-form-input
        id="title"
        v-model="data.title"
        autofocus
        trim
      />
    </b-form-group>

    <b-form-group
      label="Content"
      label-for="content"
    >
      <b-form-textarea
        v-model="data.content"
      />
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        v-model="data.startPage"
        class="mb-1"
      >
        Start page
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
  },
}
</script>
