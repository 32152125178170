<template>
  <div><div
         ref="resultsForm"
         class="repeater-form"
       >
         <b-row
           v-for="(result, index) in results"
           :id="result.id"
           :key="result.id"
           ref="row"
         >
           <b-col md="12">
             <div class="d-flex border rounded flex-column mb-1 position-relative bg-light">
               <b-link
                 class="position-absolute position-right-0 zindex-1 sidebar-repeater-delete"
                 @click="removeResult(index)"
               >
                 <feather-icon
                   icon="XIcon"
                   size="16"
                 />
               </b-link>

               <div class="row flex-grow-1 px-2 pt-1">
                 <b-col>
                   <b-form-group
                     label="Title"
                     label-for="result-title"
                   >
                     <b-form-input
                       id="result-title"
                       v-model="result.title"
                       trim
                     />
                   </b-form-group>
                   <b-form-group
                     label="URL"
                     label-for="result-url"
                   >
                     <b-form-input
                       id="result-url"
                       v-model="result.url"
                       trim
                     />
                   </b-form-group>
                   <b-form-group
                     label="Description"
                     label-for="result-description"
                   >
                     <b-form-textarea
                       id="result-description"
                       v-model="result.description"
                       trim
                     />
                   </b-form-group>
                 </b-col>
               </div>

             </div>
           </b-col>
         </b-row>
       </div>

    <b-link
      variant="primary"
      @click="addResult"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add result</span>
    </b-link>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BLink, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    BFormTextarea,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
  methods: {
    removeResult(index) {
      this.results.splice(index, 1)
    },
    addResult() {
      this.results.push({
        title: null,
        url: null,
        description: null,
      })
    },
  },
}
</script>
